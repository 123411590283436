import {React, useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

/* After a project is selected, this page will show the rooms in that project.
    The user will be able to see how many items are in each room, click on the
    room to view those items, or do basic CRUD actions on rooms */

const ROOMS_URL = '/getrooms';
const FETCH_ITEMS_URL = '/items';
const PROJECT_INFO_URL = '/project';

function ProjectHome() {
    const location = useLocation();
    const navigate = useNavigate();

    // Shortcut to go back to the project selection page
    const selectProject = () => {
        navigate('/selectproject');
    }

    // Get project ID from the URL; abort if there isn't one
    const params = new URLSearchParams(location.search);
    const projectID = params.get('projectID');
    if (!projectID) {
        selectProject();
    }

    // Set up effect hooks, etc.
    const [roomData, setRoomData] = useState(null);
    const [roomTable, setRoomTable] = useState(null);
    const [projectInfo, setProjectInfo] = useState(null);

    const { auth } = useAuth();

    /*   Button Functions   */
    
    const goHome = () => {
        navigate('/selectproject');
    };

    // Create a new room within the given project ID
    const createRoom = (event) => {
        event.preventDefault();

        // Project ID must be supplied, go back if none
        if (!projectID) {
            selectProject();
        }
        else {
            navigate('/createroom?projectID=' + projectID);
        }
    };

    const editProject = (event) => {
        event.preventDefault();

        // Project ID must be supplied, go back if none
        if (!projectID) {
            selectProject();
        }
        else {
            navigate('/editproject?projectID=' + projectID);
        }
    };

    // Fetch the project name with an effect hook upon confirming there is one
    useEffect(() => {
        try {
            if (projectID) {
                axios.get(PROJECT_INFO_URL + "?projectID=" + projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setProjectInfo(response?.data?.project)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [projectID, auth]);

    // Fetch the rooms for this project and store in effect hook; update UI if project has no rooms
    useEffect(() => {
        try {
            if (projectID) {
                axios.get(ROOMS_URL + "?projectID=" + projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => {
                        // If no rooms
                        if (response?.status === 204) {
                            // Set data empty to alert no rooms in project
                            setRoomData([])
                        }
                        else {
                            var rooms = response?.data?.rooms;
                            var index = 0;

                            // Count the items in each room and update the room object.
                            rooms.forEach(room => {
                                // Fetch items for this room ID and count them
                                axios.get(FETCH_ITEMS_URL + "?roomID=" + room.ID,
                                    {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${auth.accessToken}`
                                        },
                                        withCredentials: true
                                    }
                                ).then(
                                    response => {
                                        // Get the index of the room we are updating (avoid race condition in order of processing w/ strict mode)
                                        const updateIndex = rooms.findIndex((roomObj) => roomObj.ID === room.ID);
                                        rooms[updateIndex].items = (response?.data?.length ?? 0);
                                        index += 1;

                                        // The last item has been reached, update the effect hook with room data including item counts
                                        if (index === rooms.length) {
                                            setRoomData(rooms);
                                        }
                                    }
                                );
                            });
                        }
                    }
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [projectID, auth]);

    // When room data is updated (after api calls), render the table/alert with relevant info
    useEffect(() => {
        const GoToRoom = (event) => {
            const destRoom = event?.currentTarget?.getAttribute('value');
            if (destRoom) {
                navigate(`/roomitems?roomID=${destRoom}`);
            }
        };

        // Create a new room within the given project ID
        const createRoom = (event) => {
            event.preventDefault();
            navigate('/createroom?projectID=' + projectID);
        };

        // If room data is defined, either with data or empty
        if (roomData) {
            if (roomData.length > 0) {
                setRoomTable(
                    <div className='container'>
                        {roomData.map(room => {
                            // Something like an icon would be a nice feature (if licensable)
                            return(
                                <button key={room.ID} value={room.ID} onClick={GoToRoom} type="button" className="btn btn-ad-primary m-2 position-relative">
                                {room.name}
                                    <span className={"position-absolute top-0 start-100 translate-middle badge rounded-pill " + (room.items > 0 ? "bg-ad-danger" : "bg-ad-secondary")}>
                                    {room.items}
                                        <span className="visually-hidden">items in room</span>
                                    </span>
                                </button>
                            );
                        })}
                    </div>);
            }
            else {
                setRoomTable(
                    <div className="alert alert-ad-warning" role="alert">
                       No rooms have been created for this project yet! <button className="btn btn-sm btn-ad-success ms-2" type="button" onClick={createRoom}>Create New Room</button>
                    </div>);
            }
            
        }
        else {
            setRoomTable(
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>);
        }

    }, [projectID, roomData, auth, navigate])

    // Rendered Content
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">

                    {/* Header with breadcrumb navigation */}
                    <div className="card-header">
                        <div className="d-flex justify-content-center">
                            <nav className="breadcrumb" style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item" onClick={goHome}>Home</li>
                                    <li className="breadcrumb-item active" aria-current="page">{projectInfo?.name || ""}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="card-body">
                        
                        <div className="container">
                            <div className="row">
                                <div className="col-9">
                                    <h3>Rooms</h3>
                                </div>
                                <div className="col-3 text-end align-self-center">
                                    <button className="btn btn-ad-success" type="button" onClick={createRoom}>Create New Room</button>
                                </div>
                            </div>
                        </div>

                        {roomTable}

                        <br />
                        <br />

                        
                        <div className="row">
                            <div className="col-9">
                                <h3 className="px-9">Project Details</h3>
                            </div>
                            <div className="col-3 text-end align-self-center">
                                <button className="btn btn-ad-warning" type="button" onClick={editProject}>Edit Project</button>
                            </div>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Project Name</th>
                                    <td>{projectInfo?.name || ""}</td>
                                </tr>
                                {projectInfo?.client && <tr>
                                    <th>Company/Client</th>
                                    <td>{projectInfo?.client || ""}</td>
                                </tr>
                                }
                                <tr>
                                    <th>Project Address</th>
                                    <td>
                                        {projectInfo?.address|| ""}<br/>
                                        {projectInfo?.address2 || ""} {projectInfo?.address2 && <br/>}
                                        {`${projectInfo?.city}, ${projectInfo?.state} ${projectInfo?.zip}`  || ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectHome;