import {React, useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const CREATE_URL = "/project";

const ProjectSetup = () => {
    //const navigate = useNavigate();
    
    const { auth } = useAuth();
    const navigate = useNavigate();

    const focusField = useRef();
    const alertRef = useRef();

    const [inputs, setInputs] = useState({});
    const [alertMsg, setAlertMsg] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        focusField.current.focus();
    }, []);

    useEffect(() => {
        // Don't clear success message when inputs automatically cleared
        if (!success) {
            setAlertMsg('');
        }
    }, [inputs, success]);

    const updateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const createProject = (event) => {
        event.preventDefault();
        handleSubmit(false);
    };

    const addAnother = (event) => {
        event.preventDefault();
        handleSubmit(true);
    };

    const handleSubmit = async (cont) => {
        try {
            const response = await axios.put(CREATE_URL,
                JSON.stringify({inputs}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            );
            setSuccess(true);
            //setAlertMsg(response?.data?.message);
            setInputs("");

            // Create and continue clears form and keeps going
            if (cont) {
                setAlertMsg(response?.data?.message);
                setInputs("");
            }
            // Create Project
            else {
                navigate('/selectproject');
            }
        }
        catch (err) {
            setSuccess(false);
            if (!err?.response) {
                setAlertMsg('No server response');
            } else if (err.response?.status === 409) {
                setAlertMsg(`Project "${inputs.name}" already exists`);
                //setValidName(false);
                // Would be nice to set this field for bad project name
            }
            else {
                setAlertMsg('Project creation failure: ', JSON.stringify(err));
            }
        }
    };

    const goBack = () => navigate(-1);

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Create New Project</h2>
                        <h5>Basic Monthly Plan includes up to two separate Projects.</h5>
                    </div>
                    <div className="card-body">

                        <p ref={alertRef} className={alertMsg ? (success ? "alert alert-ad-success" : "alert alert-ad-danger") :"offscreen"} aria-live="assertive">{alertMsg}</p>

                        <form className="row g-3">

                            <div className="col-12">
                                <label htmlFor="name" className="form-label">Project Name</label>
                                <input
                                    type="text"
                                    required
                                    ref={focusField}
                                    className="form-control"
                                    name="name"
                                    id="name"
                                    onChange={updateInput}
                                    value={inputs.name || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <label htmlFor="client" className="form-label">Company or Client (optional)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="client"
                                    id="client"
                                    onChange={updateInput}
                                    value={inputs.client || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Project Address</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="address"
                                    id="address"
                                    onChange={updateInput}
                                    value={inputs.address || ""}/>
                            </div>

                            <div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address2"
                                    id="address2"
                                    onChange={updateInput}
                                    value={inputs.address2 || ""}
                                    placeholder="Apartment, studio, or floor" />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="city"
                                    id="city"
                                    onChange={updateInput}
                                    value={inputs.city || ""}
                                    />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="state"
                                    id="state"
                                    onChange={updateInput}
                                    value={inputs.state || ""}
                                    />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="zip"
                                    id="zip"
                                    onChange={updateInput}
                                    value={inputs.zip || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <div className="d-grid gap-3 d-md-flex">
                                    <button className="btn btn-ad-success" onClick={createProject}>Create Project</button>
                                    <button className="btn btn-ad-success" onClick={addAnother}>Create and Add Another</button>
                                    <button className="btn btn-ad-danger" onClick={goBack}>Go Back</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectSetup;