import {React, useState, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const ROOM_TYPES_URL = '/roomtypes';
const CREATE_ROOM_URL = '/createroom';
const PROJECT_INFO_URL = '/project';
const ROOM_DETAILS_URL = '/getroom';
const UPDATE_ROOM_URL = '/room'

function ProjectHome() {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { auth } = useAuth();

    const alertRef = useRef();

    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [roomName, setRoomName] = useState(null);
    const [roomOptions, setRoomOptions] = useState([]);
    const [infoArea, setInfoArea] = useState([]);
    const [roomExists, setRoomExists] = useState(null);
    
    // Navigate back to project home if no room/project selected
    const params = new URLSearchParams(location.search);
    const projectID = params.get('projectID');
    const roomID = params.get('roomID');
    if (!projectID) {
        navigate('/selectproject');
    }

    // If the room already exists, set the roomExists state variable; get existing room info
    useEffect(() => {
        if (roomID) {
            setRoomExists(true);
            try {
                if (roomID) {
                    axios.get(ROOM_DETAILS_URL + "?roomID=" + roomID,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${auth.accessToken}`
                        },
                        withCredentials: true
                    }
                    ).then(
                        response => {
                            console.log(response?.data);
                            setRoomName(response?.data?.roomName);
                            setSelectedRoomType(response?.data?.roomType);
                        }
                    );
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            setRoomExists(false);
        }
    }, [roomID, auth]);

    // Fetch the project name with an effect hook upon confirming there is one
    useEffect(() => {
        try {
            if (projectID) {
                axios.get(PROJECT_INFO_URL + "?projectID=" + projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setProjectName(response?.data?.project?.name)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [projectID, auth]);
    
    useEffect(() => {
        const getRoomMap = async (cb) => {
            try {
                const response = await axios.get(ROOM_TYPES_URL,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                );
                const roomMap = response?.data?.rooms.map(room => (
                    { label: room.roomTypeName, value: room.roomTypeID }
                ));
                return cb(null, roomMap);
            }
            catch (err) {
                return cb(err);
            }
        };
        getRoomMap(function (err, roomOptMap) {
            if (roomOptMap) {
                setRoomOptions(roomOptMap);
            }
        })
    }, []);

    const createRoom = (event) => {
        event.preventDefault();
        CreateRoom(false);
    };

    const addAnother = (event) => {
        event.preventDefault();
        CreateRoom(true);
    };

    const CreateRoom = (cont) => {
        // Only create a room if it does not exist already
        if (!roomExists) {
            try {
                axios.put(CREATE_ROOM_URL,
                    JSON.stringify({roomType: selectedRoomType, roomName: roomName, projectID: projectID}),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${auth.accessToken}`
                        },
                        withCredentials: true
                    }
                ).then(function (response) {
                    if (response?.status === 201) {
                        // Create room and add another
                        if (cont) {
                            setSelectedRoomType('');
                            setRoomName('');
                            setInfoArea(<p ref={alertRef} className="alert alert-ad-success">Created room "{roomName}"</p>)
                        }
                        // Create room and return to project home
                        else {
                            navigate(`/projecthome?projectID=${projectID}`);
                        }
                    }
                    else {
                    console.log('Unexpected response', response);
                    setInfoArea(<p ref={alertRef} className="alert alert-ad-danger">Unable to create room "{roomName}"</p>)
                    }
                });
            }
            catch (err) {
                if (err) {
                    console.log(err);
                }
            }

            
            setInfoArea(<p className="alert alert-ad-success">Created room "{roomName}"</p>)
        }
    };

    const updateRoomName = (event) => {
        const value = event.target.value;
        setRoomName(value);
    };

    // Clear alert when inputs touched again
    useEffect(() => {
        if (selectedRoomType) {
            setInfoArea('');
        }
    }, [selectedRoomType, roomName]);

    // Update the room (name)
    const updateRoom = (event) => {
        event.preventDefault();
        try {
            axios.post(UPDATE_ROOM_URL,
                JSON.stringify({roomID: roomID, roomName: roomName}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 200) {
                    //setSelectedRoomType('');
                    //setRoomName('');
                    setInfoArea(<p ref={alertRef} className="alert alert-ad-success">Updated room "{roomName}"</p>)
                }
                else {
                   console.log('Unexpected response', response);
                   setInfoArea(<p ref={alertRef} className="alert alert-ad-danger">Unable to update room "{roomName}"</p>)
                }
            });
        }
        catch (err) {
            if (err) {
                console.log(err);
            }
        }

        console.log(`${roomName} - ${selectedRoomType}`)
        //setInfoArea(<p className="alert alert-ad-success">Created room "{roomName}"</p>)
    }

    const goHome = () => navigate('/selectproject');
    const projectHome = () => navigate('/projecthome?projectID=' + projectID);
    const roomHome = () => navigate(`/roomitems?roomID=${roomID}`);

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">

                    {/* Header with breadcrumb navigation */}
                    <div className="card-header">
                        <div className="d-flex justify-content-center">
                            <nav className="breadcrumb" style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item" onClick={goHome}>Home</li>
                                    <li className="breadcrumb-item" onClick={projectHome}>{projectName}</li>
                                    {roomExists ? 
                                        <>
                                            <li className="breadcrumb-item" onClick={roomHome}>{roomName}</li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Room</li>
                                        </>
                                    :
                                        <>
                                            <li className="breadcrumb-item active" aria-current="page">Create Room</li>
                                        </>
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="card-body">
                        {infoArea}
                        
                        <form className="row g-3">

                            <div className="col-12">
                                <label htmlFor="roomType" className="form-label">Room Type</label>
                                <select id="roomType" className="form-select" disabled={roomExists} value={selectedRoomType || ''} onChange={(e) => setSelectedRoomType(e.target.value)}>
                                    <option defaultValue value=''>Choose a Room Type...</option>
                                    {roomOptions.map((room) => (
                                        <option key={room.value} value={room.value}>
                                            {room.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-12">
                                <label htmlFor="roomName" className="form-label">Room Name</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="roomName"
                                    id="roomName"
                                    maxLength="32"
                                    onChange={updateRoomName}
                                    value={roomName || ""}
                                    />
                            </div>

                            <div className="col-12">
                                <div className="d-grid gap-3 d-md-flex">
                                    {roomExists ?
                                        (<button className="btn btn-ad-primary" disabled={!selectedRoomType || !roomName} onClick={updateRoom}>Update Room</button>) :
                                        (<>
                                            <button className="btn btn-ad-success" disabled={!selectedRoomType || !roomName} onClick={createRoom}>Create Room</button>
                                            <button className="btn btn-ad-success" disabled={!selectedRoomType || !roomName} onClick={addAnother}>Create and Add Another</button>
                                        </>)
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectHome;