import {React, useEffect, useState, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const ROOM_STRUCTURE_URL = '/roomstructure';
const SUBCAT_FIELDS_URL = '/fields';
const CREATE_ITEM_URL = '/item';
const PROJECT_INFO_URL = '/project';
const ROOM_DETAILS_URL = '/getroom';

function RoomCreateItem() {
    const location = useLocation();
    const navigate = useNavigate();
    
    const { auth } = useAuth();
    
    const [inputs, setInputs] = useState({});
    const alertRef = useRef();

    const [selectedCat, setSelectedCat] = useState(null);
    const [selectedSubCat, setSelectedSubCat] = useState(null);
    const [labels, setLabels] = useState([null, null, null, null, null]);
    const [exitAction, setExitAction] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [roomData, setRoomData] = useState(null);

    const [roomStructure, setRoomStructure] = useState(null);

    const [catOptions, setCatOptions] = useState([]);
    const [subCatOptions, setSubCatOptions] = useState([]);
    const [success, setSuccess] = useState(false);

    const [fileNames, setFileNames] = useState([]);
    const [fileMap, setFileMap] = useState({});
    const [uploadComplete, setUploadComplete] = useState(true);
    
    // Navigate back to project home if no room/project selected
    const params = new URLSearchParams(location.search);
    const roomID = params.get('roomID');
    const projectID = params.get('projectID');
    useEffect(() => {
        if (!roomID || !projectID) {
            navigate('/selectproject');
        }
    }, [roomID, projectID, navigate]);

    // Precondition check for back buttons - if form is not empty, confirm with modal and continue if user abandons changes
    const updateExitTarget = (event) => {
        event.preventDefault();
        const trigger = event.target.id;
        if (trigger === 'backToRoom') {
            if (roomID) {
                setExitAction(`/roomitems?roomID=${roomID}`);
            }
        }
        else if (trigger === 'backToProject') {
            if (projectID) {
                setExitAction('/projecthome?projectID=' + projectID);
            }
        }
        else if (trigger === 'backHome') {
            setExitAction('/selectproject');
        }
    }

    const performExit = (event) => {
        if (exitAction) {
            navigate(exitAction);
        }
        else {
            navigate('/selectproject');
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setInputs('');
        setSelectedCat('');
        setSubCatOptions([]);
        setFileNames([]);
        setLabels([false, false, false, false, false, false, false]);
    };

    // Get the project name
    useEffect(() => {  
        try {
            if (projectID) {
                axios.get(PROJECT_INFO_URL + "?projectID=" + projectID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setProjectName(response?.data?.project?.name)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [projectID, auth]);
    
    // Get the room details to figure out room type string; store in effect hook
    useEffect(() => {
        try {
            if (roomID) {
                axios.get(ROOM_DETAILS_URL + "?roomID=" + roomID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
                ).then(
                    response => setRoomData(response?.data)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomID, auth]);

    // Get the categories for this room after the room data is stored in the effect hook
    useEffect(() => {
        try {
            if (roomData?.roomType) {
                axios.get(ROOM_STRUCTURE_URL + "?roomType=" + roomData.roomType,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                ).then(
                    response => setRoomStructure(response?.data)
                );
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [roomData]);

    // Use the room structure object to populate cat options (specifically the map)
    useEffect(() => {
        if (roomStructure) {
            // Sort categories alphabetically
            var catNames = [];
            Object.keys(roomStructure).forEach(item => {
                catNames.push({
                    value: item,
                    label: roomStructure[item].parentCatName
                }); 
            })
            
            const sortedCats = Object.values(catNames).sort((prev, next) => {
                // Always bump "Other" to the bottom of the list
                if (prev.label.toLowerCase() === 'other') return 1;
                if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                return 0;
            });
            setCatOptions(sortedCats);
            setSelectedCat('');
        }
    }, [roomStructure]);

    // Triggers when Category is changed; populate the subcategory options and sort them alphabetically
    const handleChangeCat = (event) => {
        const selectedCatID = event.target.value;
        setSelectedCat(selectedCatID);
        setLabels([false, false, false, false, false, false, false]);
        setSelectedSubCat('');

        if (selectedCatID !== '' && selectedCatID in roomStructure) {

            const subCats = roomStructure[selectedCatID].subcatList.map((id, index) => {
                return {
                    label: roomStructure[selectedCatID].subcatNameList[index], value: id
                }
            });
            
            // Sort subcategories alphabetically as above
            const sortedSubCats = subCats.sort((prev, next) => {
                // Always bump "Other" to the bottom of the list
                if (prev.label.toLowerCase() === 'other') return 1;
                if(prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
                if(prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
                return 0;
            });
            setSubCatOptions(sortedSubCats);
        }
        else {
            setSubCatOptions([]);
        }
    };

    // Triggers when sub-category is changed; look up the field options and give them
    const handleChangeSubCat = (event) => {
        const selectedSubcatID = event.target.value;
        setSelectedSubCat(selectedSubcatID);

        if (selectedSubcatID !== '') {
            // Get the properties (fields) for this subcat; show the respective fields
            try {
                axios.get(SUBCAT_FIELDS_URL + "?subCatID=" + selectedSubcatID,
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                ).then(
                    response => {
                        const fieldList = response?.data.map(field => field.label);
                        setLabels(fieldList);
                    }
                )
            }
            catch (err) {
                console.log(err);
            }
        }
    };

    // Update values when field is changed
    const updateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const submitForm = (event) => {
        event.preventDefault();
        createItem(false);
    };

    const addAnother = (event) => {
        event.preventDefault();
        createItem(true);
    };

    const createItem = (cont) => {
        try {
            axios.put(CREATE_ITEM_URL,
                JSON.stringify({projectID, roomID, catID: selectedCat, subcatID: selectedSubCat, inputs, files: fileMap}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then(function (response) {
                if (response?.status === 201) {
                    // Create and add another
                    if (cont) {
                        setSuccess(true);
                        setInputs("");
                        setSelectedSubCat('');
                        setSelectedCat('');
                        setSubCatOptions([]);
                        setLabels([false, false, false, false, false, false, false]);
                    }
                    // Create and go back to room
                    else {
                        navigate(`/roomitems?roomID=${roomID}`);
                    }
                }
                else {
                   console.log('Unexpected response', response);
                }
            });
        }
        catch (err) {
            if (err) {
                console.log(err);
            }
        }
    };

    // Clear alert when inputs touched again
    useEffect(() => {
        setSuccess(false);
    }, [selectedCat, selectedSubCat]);

    // Event listener for file selection
    const handleFileChange = (event) => {

        // Reset the upload complete flag to false
        setUploadComplete(false);
        setFileNames([]);

        const files = event.target.files;
        if (files && files.length > 0) {
            // Update the state variable with the new list of file names
            const names = Array.from(files).map(file => file.name);
            console.log('update files set files where files is files:', names)
            setFileNames(names);

            // Bang the endpoint to upload the files
            uploadFiles(files);
        }
        else {
            setUploadComplete(true);
            setFileNames([]);
        }
    }

    const uploadFiles = async (files) => {

        // Prepare form data for POST request and add files
        const formData = new FormData();
        Object.keys(files).forEach(key => {
            formData.append(files.item(key).name, files.item(key))
        })
    
        // Execute POST request
        const response = await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${auth.accessToken}`
          }
        });

        // Parse response to verify files were received
        if (response.data.length > 0) {
            const uploads = response.data.map(item => item.filename);

            // Check if all uploaded files match the attachment list
            if (fileNames.every((element, index) => element === uploads[index])) {
                setUploadComplete(true);

                // Create a file manifest for the database
                const manifest = response.data.reduce((acc, item) => {

                    // Shorten the filename if it is too long; preserve the extension & append it
                    var file_label = item.filename;
                    if (item.filename.length > 36) {
                        // Extract the extension (if there is one)
                        const lastIndex = item.filename.lastIndexOf('.');

                        // Shorten the file name (sans extension)
                        file_label = item.filename.substring(0, 31) + '[...]';

                        // Append the extension if there is one
                        if (lastIndex !== -1) {
                            file_label += item.filename.substring(lastIndex);
                        }
                    }
                    acc[file_label] = item.remote;
                    return acc;
                }, {});
                setFileMap(manifest);
            }
            else {
                console.log('mismatched file manifest - should have cleared?');
                console.log(fileNames);
                console.log(uploads);
            }
        }
    };
    
    const goHome = () => navigate('/selectproject');
    const projectHome = () => navigate('/projecthome?projectID=' + projectID);
    
    // Navigate back to room home on button press
    const roomHome = (event) => {
        event.preventDefault();
        if (roomID) {
            navigate(`/roomitems?roomID=${roomID}`);
        }
        else {
            navigate('/selectproject');
        }
    }
    
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">

                    {/* Header with breadcrumb navigation */}
                    <div className="card-header">
                        <div className="d-flex justify-content-center">
                            <nav className="breadcrumb" style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* "Back to Room" button with confirmation modal target override if fields not empty */}
                                    {(inputs?.field1 || inputs?.field2 || inputs?.field3 || inputs?.field4 || inputs?.field5 || inputs?.field6 || inputs?.notes) ? (
                                        <>
                                            <li className="breadcrumb-item" id="backHome" onClick={updateExitTarget} data-bs-toggle="modal" data-bs-target="#unsavedModal">Home</li>
                                            <li className="breadcrumb-item" id="backToProject" onClick={updateExitTarget} data-bs-toggle="modal" data-bs-target="#unsavedModal">{projectName}</li>
                                            <li className="breadcrumb-item" id="backToRoom" onClick={updateExitTarget} data-bs-toggle="modal" data-bs-target="#unsavedModal">{roomData?.roomName}</li>
                                            <li className="breadcrumb-item active" aria-current="page">Create Item</li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="breadcrumb-item" onClick={goHome}>Home</li>
                                            <li className="breadcrumb-item" onClick={projectHome}>{projectName}</li>
                                            <li className="breadcrumb-item" onClick={roomHome}>{roomData?.roomName}</li>
                                            <li className="breadcrumb-item active" aria-current="page">Create Item</li>
                                        </>
                                    )}
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="card-body">
                        
                        <p ref={alertRef} role="alert" className={success ? "alert alert-ad-success" : "offscreen"}
                        aria-live="assertive">Successfully Created New Item</p>

                        <form className="row g-3">

                        <div className="col-6">
                            <label htmlFor="projectName" className="form-label">Project Name</label>
                            <input disabled id="projectName" value={projectName} className="form-control" />
                        </div>
                        <div className="col-6">
                            <label htmlFor="roomName" className="form-label">Room Name</label>
                            <input disabled id="roomName" value={roomData?.roomName} className="form-control" />
                        </div>

                            <div className="col-12">
                                <label htmlFor="category" className="form-label">Category</label>
                                <select
                                    id="category" className="form-select"
                                    value={selectedCat || ''} onChange={handleChangeCat}
                                >
                                    <option defaultValue value=''>Choose a Category...</option>
                                    {catOptions.map((cat) => (
                                        <option key={cat.value} value={cat.value}>
                                            {cat.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-12">
                                <label htmlFor="subCategory" className="form-label">Sub Category</label>
                                <select id="subCategory" className="form-select"
                                    value={selectedSubCat || ''} onChange={handleChangeSubCat}
                                >
                                <option defaultValue value=''>Choose a Subcategory...</option>
                                {subCatOptions.map((subCat) => (
                                    <option key={subCat.value} value={subCat.value}>
                                        {subCat.label}
                                    </option>
                                ))}
                                </select>
                            </div>

                            {/* Element 0 - Manufacture/Note/Purchased */}
                            {labels[0] && 
                            <div className="col-md-12">
                                <label htmlFor="item" className="form-label">{labels[0]}</label>
                                <input type="text" className="form-control"
                                id="field1" name="field1"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field1 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            {/* Element 1 - Color/Series/Note/Model */}
                            {labels[1] && 
                            <div className="col-md-12">
                                <label htmlFor="manufacturer" className="form-label">{labels[1]}</label>
                                <input type="text" className="form-control"
                                id="field2" name="field2"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field2 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            {/* Element 2 - Purchased/Note/Style/ExtColor */}
                            {labels[2] && 
                            <div className="col-md-12">
                                <label htmlFor="model" className="form-label">{labels[2]}</label>
                                <input type="text" className="form-control"
                                id="field3" name="field3"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field3 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            {/* Element 3 - Purchased/Note */}
                            {labels[3] && 
                            <div className="col-md-12">
                                <label htmlFor="modelNumber" className="form-label">{labels[3]}</label>
                                <input type="text" className="form-control"
                                id="field4" name="field4"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field4 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            {/* Element 4 - IntColor */}
                            {labels[4] && 
                            <div className="col-md-12">
                                <label htmlFor="color" className="form-label">{labels[4]}</label>
                                <input type="text" className="form-control"
                                id="field5" name="field5"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field5 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            {/* Element 5 - IntColor */}
                            {labels[5] && 
                            <div className="col-md-12">
                                <label htmlFor="color" className="form-label">{labels[5]}</label>
                                <input type="text" className="form-control"
                                id="field6" name="field6"
                                maxLength="255"
                                autoComplete="on"
                                value={inputs.field6 || ''}
                                onChange={updateInput}/>
                            </div>
                            }
                            
                            <div className="col-md-12">
                                <label htmlFor="color" className="form-label">Notes</label>
                                <input type="text" className="form-control"
                                id="notes" name="notes"
                                maxLength="255"
                                value={inputs.notes || ''}
                                onChange={updateInput}/>
                            </div>
                            
                            {/* Element 6 - Photo (set to always shown) */}
                            <div className="col-md-12">
                                <input type="file" multiple id="itemAttachments" style={{display: "none"}} onChange={handleFileChange}/>
                                <label htmlFor="itemAttachments" className="btn btn-ad-primary">Select Files...</label>
                                {fileNames.length > 0 && (
                                <div>
                                    <br />
                                    <h5>{uploadComplete ? "Attached Files:" : "Uploading..."}</h5>
                                    <div className="d-grid gap-3 d-md-flex">
                                        {fileNames.map((name) => (
                                            <button type="button" className="btn btn-outline-info" disabled>
                                                {uploadComplete ? <span>✅</span> : <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                <span role="status"> {name}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                )}
                            </div>

                            <div className="container gy-4 text-center">
                                <div className="row gx-3">
                                    <div className="col-5">
                                        <button disabled={!selectedCat || !selectedSubCat || selectedCat === '' || selectedSubCat === '' || !uploadComplete} className="btn btn-ad-success btn-lg col-12" onClick={submitForm}>Create Item</button>
                                    </div>
                                    <div className="col-5">
                                        <button disabled={!selectedCat || !selectedSubCat || selectedCat === '' || selectedSubCat === '' || !uploadComplete} className="btn btn-ad-success btn-lg col-12" onClick={addAnother}>Create and Add Another</button>
                                    </div>
                                    <div className="col-2">
                                        <button className="btn btn-ad-danger btn-lg col-12" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#clearFormConfirmModal">Clear Form</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="unsavedModal" data-bs-backdrop="static" data-bs-leopard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Unsaved Changes</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to leave without saving this item?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ad-secondary" data-bs-dismiss="modal">Keep Working</button>
                            <button type="button" className="btn btn-ad-danger" data-bs-dismiss="modal" onClick={performExit}>Abandon Changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="clearFormConfirmModal" data-bs-backdrop="static" data-bs-leopard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Unsaved Changes</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to clear the form without saving this item?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-ad-secondary" data-bs-dismiss="modal">Keep Working</button>
                            <button type="button" className="btn btn-ad-danger" data-bs-dismiss="modal" onClick={clearFields}>Clear Form</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RoomCreateItem;